<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <p>Selecteer de facturen die u wilt ontvangen</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-list three-line>
                    <v-list-item-group v-model="selected" multiple>
                        <v-list-item>
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active" />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Abo delicious 02341 </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span class="text--secondary">Factuurnummer: </span>
                                        <span class="text--primary">2022-02341</span>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <span class="text--secondary">Datum: </span>
                                        <span class="text--primary">01-01-2022</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text>
                                        <v-chip color="primary" outlined>&euro; 34,50</v-chip>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </template>
                        </v-list-item>

                        <v-list-item>
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active" />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Abo Hobo 02342</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span class="text--secondary">Factuurnummer: </span>
                                        <span class="text--primary">2022-02342</span>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <span class="text--secondary">Datum: </span>
                                        <span class="text--primary">01-01-2022</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text>
                                        <v-chip color="primary" outlined>&euro; 24,50</v-chip>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </template>
                        </v-list-item>

                        <v-list-item>
                            <template v-slot:default="{ active }">
                                <v-list-item-action>
                                    <v-checkbox :input-value="active" />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Abo delicious 98340</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span class="text--secondary">Factuurnummer: </span>
                                        <span class="text--primary">2021-98340</span>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <span class="text--secondary">Datum: </span>
                                        <span class="text--primary">01-09-2021</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text>
                                        <v-chip color="primary" outlined>&euro; 10,10</v-chip>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </template>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "InvoiceView",

    data: () => ({
        selected: [],
    }),
};
</script>
