<template>
    <v-app>
        <!-- <v-app-bar color="white" app flat prominent shrink-on-scroll v-if="appBarVisible">
            <v-app-bar-title>
                <v-img
                    alt="Blink logo"
                    class=""
                    contain
                    src="./assets/logo-blink.svg"
                    transition="scale-transition"
                    style="width: 100%; max-height: 100px"
                />
            </v-app-bar-title>

            <v-spacer></v-spacer>

            <v-btn text outlined>Uitloggen</v-btn>

            <v-menu bottom left offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on">
                        Huidige relatie
                        <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item>
                        <v-list-item-title>Andere relatie</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title>Andere relatie 2</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar> -->
        <v-navigation-drawer app v-model="navBarOpen" v-if="appBarVisible">
            <v-list>
                <v-list-item>
                    <v-list-item-avatar width="100%">
                        <v-img src="./assets/logo-blink.svg" contain />
                    </v-list-item-avatar>
                </v-list-item>

                <v-list-item :to="{ name: 'relation_select' }">
                    <v-list-item-content>
                        <v-list-item-title class="text-h6"> Steven Heijtel </v-list-item-title>
                        <v-list-item-subtitle>#766189263</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>

            <v-divider />

            <v-list nav dense>
                <v-list-item :to="{ name: 'subscriptions' }">
                    <v-list-item-icon><v-icon>mdi-newspaper-variant</v-icon></v-list-item-icon>
                    <v-list-item-title>Abonnementen</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{ name: 'invoices' }">
                    <v-list-item-icon><v-icon>mdi-receipt-text</v-icon></v-list-item-icon>
                    <v-list-item-title>Facturen</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app v-if="appBarVisible" hide-on-scroll dense color="primary" dark flat>
            <v-app-bar-nav-icon @click="navBarOpen = !navBarOpen">
                <!-- <v-img src="./assets/logo-blink.svg" contain width="32" /> -->
            </v-app-bar-nav-icon>
            <v-app-bar-title>{{ pageTitle }}</v-app-bar-title>
            <v-spacer />
            <v-btn icon color="white" v-if="invoiceControls"><v-icon>mdi-select-all</v-icon></v-btn>
            <v-btn icon color="white" v-if="invoiceControls"><v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        navBarOpen: null,
    }),

    computed: {
        appBarVisible() {
            if (this.$route.name === "login") return false;
            if (this.$route.name === "relation_select") return false;

            return true;
        },

        invoiceControls() {
            return this.$route.name === "invoices";
        },

        pageTitle() {
            const title = this.$route.meta.title || undefined;

            if (title) return title;

            return "Klantenportaal";
        },
    },
};
</script>
