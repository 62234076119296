<template>
    <v-container fluid>
        <v-row>
            <v-col class="text-center">
                <v-img src="../assets/logo-blink.svg" style="width: 100%; max-height: 100px" contain />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h1 class="text-center mb-5">Welkom Steven Heijtel</h1>
                <p>
                    Wat goed; je hebt zelfs meerdere relatiesnummers bij ons! Je kan alleen maar met 1 tegelijk
                    inloggen. Met welke wil je verder gaan?
                </p>
                <p>Mocht je je adres willen wijzigen moet je dat bij beide apart doen.</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 16 : 2" @click="selectRelation">
                        <v-card-title>#766189263</v-card-title>
                        <v-card-subtitle>Steven Heijtel</v-card-subtitle>
                        <v-card-text>
                            <v-list three-line flat>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-img src="../assets/bobo-logo-webshop.jpg" contain width="96" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Bobo</v-list-item-title>
                                        <v-list-item-subtitle class="text--primary">
                                            M. Dottingalaan 113 te Weesp
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle> Tot wederopzegging </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider />
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-img src="../assets/logo_okki_kleur.png" contain width="96" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Okki</v-list-item-title>
                                        <v-list-item-subtitle class="text--primary">
                                            M. Dottingalaan 113 te Weesp
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle> tot 18 januari 2023 </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-hover>

                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 16 : 2" class="mt-4" ripple @click="selectRelation">
                        <v-card-title>#8812623</v-card-title>
                        <v-card-subtitle>Steven Heijtel</v-card-subtitle>
                        <v-card-text>
                            <v-list three-line flat>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-img src="../assets/logo_okki_kleur.png" contain width="96" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Okki</v-list-item-title>
                                        <v-list-item-subtitle class="text--primary">
                                            Pallas 48 te Amstelveen
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle> Tot wederopzegging </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "RelationSelectView",

    methods: {
        selectRelation() {
            setTimeout(() => {
                this.$router.push({
                    name: "subscriptions",
                });
            }, 150);
        },
    },
};
</script>
