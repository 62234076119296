<template>
    <v-container fluid>
        <v-row>
            <v-col class="text-center">
                <v-img src="../assets/logo-blink.svg" style="width: 100%; max-height: 100px" contain />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h1 class="text-center mb-5">Welkom bij het Blink self-service portaal</h1>
                <p>
                    Hier kan je een bezorgklacht doorgeven, facturen downloaden en je abonnement- en contactgevens
                    aanpassen.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-form>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="4">Je e-mailadres:</v-col>
                            <v-col cols="12" md="8">
                                <v-text-field single-line full-width hide-details outlined />
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-btn :to="{ name: 'relation_select' }" block type="submit" color="primary">
                                    Stuur mij een link om in te loggen
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container></v-form
                >
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p class="mt-4">Hulp nodig? Neem contact op met <a href="mailto:">de Blink klantenservice</a>.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "LoginView",
};
</script>
