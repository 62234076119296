<template>
    <v-container fluid>
        <a class="text-h6 mb-5 d-block" href="#">Ik wil een extra abonnement bestellen</a>
        <v-card class="mb-4">
            <v-img src="../assets/bobo-logo-webshop.jpg" height="160" contain />
            <v-card-title>
                Bobo
                <v-chip color="primary" class="ml-auto" small>tot wederopzegging</v-chip>
            </v-card-title>
            <v-card-subtitle>M. Dotingalaan 113 te Weesp</v-card-subtitle>
            <v-card-text>
                <v-btn text outlined block color="primary" class="mb-2">Bezorgklacht doorgeven</v-btn>
                <v-btn text outlined block color="primary" class="mb-2">Bezorggegevens wijzigen</v-btn>
                <v-btn text outlined block color="primary" class="mb-2">Een ander blad ontvangen</v-btn>
            </v-card-text>
        </v-card>

        <v-card class="mb-4">
            <v-img src="../assets/logo_okki_kleur.png" height="160" contain />
            <v-card-title>
                Okki
                <v-chip color="primary" class="ml-auto" small>tot 18 januari 2023</v-chip>
            </v-card-title>
            <v-card-subtitle>M. Dotingalaan 113 te Weesp</v-card-subtitle>
            <v-card-text>
                <v-btn text outlined block color="primary" class="mb-2">Bezorgklacht doorgeven</v-btn>
                <v-btn text outlined block color="primary" class="mb-2">Bezorggegevens wijzigen</v-btn>
                <v-btn text outlined block color="primary" class="mb-2">Een ander blad ontvangen</v-btn>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "SubscriptionView",

    components: {},
};
</script>
