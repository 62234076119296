import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import SubscriptionView from "../views/SubscriptionView.vue";
import InvoiceView from "../views/InvoiceView.vue";
import RelationSelectView from "../views/RelationSelectView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "login",
        component: LoginView,
    },
    {
        path: "/relation",
        name: "relation_select",
        component: RelationSelectView,
    },
    {
        path: "/subscriptions",
        name: "subscriptions",
        component: SubscriptionView,
        meta: {
            title: "Abonnementen",
        },
    },
    {
        path: "/invoices",
        name: "invoices",
        component: InvoiceView,
        meta: {
            title: "Facturen",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
